// src/pages/AdminProfilePage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Typography,
  Grid2,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import CreateUserAdmin from '../../components/widgets/buttons/createUserAdmin';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getAllUsers } from '../../api/creatorApi';



const CreatorsListPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '_createdDate', direction: 'desc' });
  const [filterUserType, setFilterUserType] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
    const user_type = localStorage.getItem('user_type');
  if (user) {
    if (user_type === 'admin') {
    fetchUsers(isMounted);
  } else if (user_type === 'brandAdmin') {
    const brandId = localStorage.getItem('brand_id');
    fetchUsers(isMounted, brandId);
  } else {
    navigate('/logout');
  }
}});

return () => {
  isMounted = false;
  unsubscribe();
};
}, [navigate]);

  const fetchUsers = async (isMounted) => {
    try {
      const usersData = await getAllUsers();
      console.log('Fetched users data:', usersData); // Debug log
      if (isMounted) {
        if (Array.isArray(usersData)) {
          setUsersData(usersData);
          console.log('Set users state:', usersData); // Debug log
        } else {
          console.error('Fetched data is not an array:', usersData);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      if (isMounted) {
        setError('Failed to fetch users. Please try again later.');
        setIsLoading(false);
      }
    }
  };

  const handleEditClick = (userData) => {
    console.log('Editing user:', userData); // Debugging user object
    navigate(`/users/edit`, { state: { userData } });
  };

  const handleViewClick = (userData) => {
    console.log('Viewing user:', userData); // Debugging user object
    navigate(`/users/detail`, { state: { userData } });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterUserType(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = [...usersData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredUsers = sortedUsers.filter((user) =>
    (user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.user_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (filterUserType === '' || user.user_type === filterUserType)
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <CircularProgress />
          </Container>
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <Typography color="error">{error}</Typography>
          </Container>
        </Box>
      </Box>
    );
  }

  console.log('Rendering users:', usersData); // Debug log

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Users</Typography>
            </Grid2>
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <Grid2 item xs={3} minWidth={'300px'}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Filter by User Type</InputLabel>
                  <Select
                    value={filterUserType}
                    onChange={handleFilterChange}
                    label="User Type"
                  >
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                    {/* Add more user types as needed */}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 item xs={6} />
              <Grid2 item xs={3} container justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid2 item xs={8} minWidth={'300px'}>
                  <TextField
                    label="Search Users"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Grid2>
                <Grid2 item xs={4}>
                  <CreateUserAdmin />
                </Grid2>
              </Grid2>
            </Grid2>
            <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'displayName'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('displayName')}
                      >
                        <strong>Name</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'email'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('email')}
                      >
                        <strong>Email</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'user_type'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('user_type')}
                      >
                        <strong>User Type</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === '_createdDate'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('_createdDate')}
                      >
                        <strong>Created Date</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((userData) => (
                    <TableRow key={userData.userId} sx={{ cursor: 'pointer' }}>
                      <TableCell>{userData.displayName}</TableCell>
                      <TableCell>{userData.email}</TableCell>
                      <TableCell>{userData.user_type}</TableCell>
                      <TableCell>{new Date(userData._createdDate).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewClick(userData)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEditClick(userData)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CreatorsListPage;
