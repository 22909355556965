import React, {useContext, useState, useEffect } from 'react';
import { Grid2, Avatar, Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, Box, Badge, MenuItem, Menu, IconButton, Skeleton, Divider } from '@mui/material';
import { Home, CalendarToday, AccountBalance, BusinessCenter, InsertChart, ShoppingCart, Settings, Assignment, HeadsetMic, Layers } from '@mui/icons-material';
import { styled } from '@mui/system';
import { Link, useLocation } from 'react-router-dom';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchBar from '../widgets/dashboard/SearchBar';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import { getUnreadMessages } from '../../api/messaging';




const drawerWidth = 280;

// Custom styles for the "NEW" badge
const NewBadge = styled('div')({
  fontSize: '10px',
  color: '#fff',
  backgroundColor: '#ff4757',
  padding: '2px 8px',
  borderRadius: '10px',
  marginLeft: 'auto',
});

const ProfileAvatar = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Get the current location
  const user_type = localStorage.getItem('user_type');
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [photoURL, setPhotoURL] = useState('');
  const [anchorEl, setAnchorEl] = useState(null); // State for the dropdown menu
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  
  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if(!user_type) {
      navigate('/logout');
    }
    fetchUser();
    fetchUnreadMessages();
  }, []);

  const fetchUser = async () => {
      
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const firstInitial = user.email.charAt(0).toUpperCase(); // Get first letter and capitalize it
        console.log(user.photoURL); // Ensure this is only called if user is available
        setPhotoURL(user.photoURL);
        setUserDetails(user);
        setIsLoading(false);
      } else {
        console.log('No user is signed in');
      } 
         
  };

  const fetchUnreadMessages = async () => {
    const unreadMessages = await getUnreadMessages();
    setUnreadMessages(unreadMessages);
    console.log(unreadMessages.messagesCount);
    setUnreadMessageCount(unreadMessages.messagesCount);

  }


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut().then(() => {
      navigate('/logout');
    });
    handleMenuClose();
  };

  const handleSettings = () => {
    navigate('/settings');
    handleMenuClose();
  };

  const handleProfile = () => {
    if (user_type === 'creator') {
    navigate('/creators/profile');
    } else {
    navigate('/profile');
    }
    handleMenuClose();
  };


  const handleMessaging = () => {
    navigate('/messaging');
    handleMenuClose();
  };




  return (
    <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
      <Grid2 item xs>
      </Grid2>
      <Grid2 item>
        <Grid2 container alignItems="center" spacing={2}>
          <Grid2 item>
            {/* Avatar with Clickable Dropdown */}
            <IconButton onClick={handleMenuOpen}>
              {!isLoading ? (
                <Badge badgeContent={unreadMessageCount} color="error">
                  <Avatar alt="Profile Image" src={photoURL} />
                </Badge>
              ) : (
                <Skeleton variant="rectangular" width={210} height={118} />
              )}
            </IconButton>

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}

              sx={{
                padding: '8px', // Add padding to the menu
                borderRadius: '20px', // Add rounded corners
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional: add subtle shadow
              }}
            >
              <Grid2 sx={{marginLeft: 2}}>
              <Typography variant='subtitle2' sx={{fontWeight: 'bold', marginRight: 2}}>{userDetails.email}</Typography>
              <Typography  variant='subtitle2' sx={{}}>{user_type}</Typography>
              </Grid2>
              <Divider sx={{ my: 2 }} />

              <MenuItem onClick={handleProfile}>
              <Typography  variant='subtitle2' sx={{}}>My Profile</Typography>
              </MenuItem>

               <MenuItem onClick={handleMessaging}>
                <Badge badgeContent={unreadMessageCount} color="error" sx={{ mr: 2 }}>
                  <Typography variant='subtitle2' sx={{}}>Messaging</Typography>
                </Badge>
              </MenuItem>
              
              <MenuItem onClick={handleSettings}>
              <Typography  variant='subtitle2' sx={{}}>  Settings</Typography>
              </MenuItem>

              <Divider sx={{ my: 2 }} />

              <MenuItem onClick={handleLogout}>
                <LogoutIcon sx={{ mr: 1 }} />
                <Typography  variant='subtitle2' sx={{}}>    Logout</Typography>
              </MenuItem>
            </Menu>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default ProfileAvatar;
