// src/pages/AdminProfilePage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Typography,
  Grid2,
  Paper,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  TableSortLabel,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getAllBrands } from '../../api/brandApi';
import CreateNewBrand from '../../components/widgets/buttons/createBrand';

const BrandListPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [brands, setBrands] = useState([]);

  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '_createdDate', direction: 'desc' });
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchBrands(isMounted);
      } else {
        navigate('/logout');
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [navigate]);

  const fetchBrands = async (isMounted) => {
    try {
      const brandsData = await getAllBrands();
      console.log('Fetched brands data:', brandsData); // Debug log
      if (isMounted) {
        if (Array.isArray(brandsData)) {
          setBrands(brandsData);
          console.log('Set brands state:', brandsData); // Debug log
        } else {
          console.error('Fetched data is not an array:', brandsData);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
      if (isMounted) {
        setError('Failed to fetch brands. Please try again later.');
        setIsLoading(false);
      }
    }
  };

  const handleRowClick = (brand) => {
    navigate(`/brands/detail`, { state: { brand } });
  };

  const handleEditClick = (brand) => {
    navigate(`/brands/edit`, { state: { brand } });
  };

  const handleViewClick = (brand) => {
    navigate(`/brands/detail`, { state: { brand } });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedBrands = [...brands].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredBrands = sortedBrands.filter((brand) =>
    brand.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <CircularProgress />
          </Container>
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
            <Typography color="error">{error}</Typography>
          </Container>
        </Box>
      </Box>
    );
  }

  console.log('Rendering brands:', brands); // Debug log

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Brands</Typography>
              <CreateNewBrand />
            </Grid2>
            <TextField
              label="Search Brands"
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{ mb: 2 }}
            />
            <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'company_name'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('company_name')}
                      >
                        <strong>Company Name</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === '_createdDate'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('_createdDate')}
                      >
                        <strong>Created Date</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredBrands.map((brand) => (
                    <TableRow key={brand._id} sx={{ cursor: 'pointer' }}>
                      <TableCell>{brand.company_name}</TableCell>
                      <TableCell>{new Date(brand._createdDate).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewClick(brand)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEditClick(brand)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default BrandListPage;
