import axios from 'axios';
import { getAuth } from 'firebase/auth';

const BASE_URL = 'https://ostendo.nucleotto.com/api/campaign/messages';

export const postMessage = async (messageData) => {
  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const authToken = await currentUser.getIdToken();
    const response = await axios.post(
      `${BASE_URL}/message`,
     { messageData: messageData},
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error creating brand', error);
    throw error; // Handle errors appropriately
  }
};

export const getUserMessages = async () => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const authToken = await currentUser.getIdToken();
      const response = await axios.get(
        `${BASE_URL}/create?userId=${currentUser.uid}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error creating brand', error);
      throw error; // Handle errors appropriately
    }
  };

  export const getMessage = async (conversationId) => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const authToken = await currentUser.getIdToken();
      const response = await axios.get(
        `${BASE_URL}/create?conversationId=${conversationId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error creating brand', error);
      throw error; // Handle errors appropriately
    }
  };


  export const getUnreadMessages = async () => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const authToken = await currentUser.getIdToken();
      const response = await axios.get(
        `${BASE_URL}/get-unread`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error creating brand', error);
      throw error; // Handle errors appropriately
    }
  };

  
  export const getUsersMessagesAll = async () => {
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const authToken = await currentUser.getIdToken();
      const response = await axios.get(
        `${BASE_URL}/get`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        }
      );
  
      return response.data.messages;
    } catch (error) {
      console.error('Error creating brand', error);
      throw error; // Handle errors appropriately
    }
  };


