import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Avatar,
  Chip,
  Grid2,
  FormControl,
  MenuItem, 
  InputLabel, 
  Select,
  IconButton,
  Button
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { statusColors } from '../../../config/status';
import { useNavigate } from 'react-router-dom';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { formatDate } from '../../../utils/functions';


const CreatorApplicationsSummaryTable = ({ applications }) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState('All');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [expandedRows, setExpandedRows] = useState({});

  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleRowClick = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Filter the campaign data based on the selected status
  const filteredCampaigns = statusFilter === 'All' 
    ? applications 
    : applications.filter((campaign) => campaign.status === statusFilter);



  const handleViewOffer = (application) => {
 
   navigate(`/creators/offered/detail/${application.campaignId}`, {state: { application }});
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const sortedApplications = React.useMemo(() => {
    if (!Array.isArray(applications)) return [];
    if (!sortConfig.key) return applications;

    const sorted = [...applications].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [applications, sortConfig]);

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />;
    }
    return null;
  };

  return (
    <Grid2 container width='100%' alignItems="center" justifyContent="space-between">
      <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
             
              <TableCell onClick={() => handleSort('campaignName')} style={{ cursor: 'pointer' }}>
                <strong>Campaign Name</strong> {getSortIcon('campaignName')}
              </TableCell>
              <TableCell onClick={() => handleSort('status')} style={{ cursor: 'pointer' }}>
                <strong>Status</strong> {getSortIcon('status')}
              </TableCell>
              <TableCell>
                <strong>Placements</strong>
              </TableCell>
              <TableCell>
                <strong>Date Applied</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
              <TableCell>
                <strong></strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedApplications.map((application, index) => (
              <React.Fragment key={application._id}>
              <TableRow onClick={() => handleRowClick(index)} style={{ cursor: 'pointer' }}>
               
                <TableCell>{application.campaignName}</TableCell>
                <TableCell>{application.status}</TableCell>
                <TableCell>{application.placementsAppliedTo.length}</TableCell>
                <TableCell>{formatDate(application.applicationDate)}</TableCell>
                <TableCell>
                  <IconButton size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small">
                    {expandedRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>
                  {application.status === 'Offered' && (
                  <Button variant="contained" onClick={() => handleViewOffer(application)} sx={{ borderRadius: '10px', mr: 2 }}>View</Button>
)}
                  </TableCell>
              
              </TableRow>
              {expandedRows[index] && (
                <TableRow>
                  <TableCell colSpan={5}>
                    {Array.isArray(application.placementsAppliedTo) && application.placementsAppliedTo.length > 0 ? (
                      <Box>
                        {application.placementsAppliedTo.map((placement, idx) => (
                          <Box key={idx} sx={{ mb: 1 }}>
                            <Typography variant="body2">
                              <strong>Placement ID:</strong> {placement.placementId || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Proposed Fee:</strong> {placement.proposedFee || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Status:</strong> {placement.status || 'N/A'}
                            </Typography>
                            <Typography variant="body2">
                              <strong>Comment:</strong> {placement.comments || ''}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      'No Placements'
                    )}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Grid2>
  );
};

export default CreatorApplicationsSummaryTable;
