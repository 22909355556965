import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Container, Typography, Grid2, Skeleton, Avatar, Tabs, Tab, TextField, Button, Divider, Switch, Chip } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';


const AdvertiserProfilePage = () => {
  
  const [isLoading, setIsLoading] = useState(true);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0); // For managing 
  const [userData, setUserData] = useState({});
  const [updateData, setUpdateData] = useState({});
  const navigate = useNavigate();

  const handleEditClick = async () => {
    navigate('/profile/edit', { state: { userData } });
  };


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout')
    }
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const accessToken = await user.getIdToken();
        const userId = user.uid;
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/user/getUser?userId=${userId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setUserData(response.data.result || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          {/* Profile Avatar and Header */}
          <Box>
            <ProfileAvatar />
          </Box>

          {/* Tabs for Profile Sections */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Profile Tabs"
            sx={{ marginBottom: 2, marginTop: 2 }}
          >
            <Tab label="Personal Information" />
            <Tab label="Account Settings" />
            <Tab label="Company Information" />
            <Tab label="Billing Information" />
          </Tabs>

          {/* Profile Content */}
          <Grid2 container spacing={4} direction="column">
            {selectedTab === 0 && (
              <>
                {/* Personal Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Avatar src={userData.profile_image} sx={{ width: 120, height: 120, mb: 2 }} />
                  <Typography variant="h5" fontWeight="bold">{userData.firstName} {userData.lastName} </Typography>
                  <Typography variant="subtitle1" color="textSecondary">{userData.jobTitle}</Typography>
                </Grid2>
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Personal Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2} width="600px" direction="column">
                  <Grid2 item>
                      <Typography variant="subtitle1">First Name: {userData.firstName}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Typography variant="subtitle1">Last Name: {userData.lastName}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Typography variant="subtitle1">Email: {userData.email}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Typography variant="subtitle1">Phone Number: {userData.mobile}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                    <Typography variant="subtitle1">Job Title: {userData.jobTitle}</Typography>
                    </Grid2>
                    <Grid2 item xs={12}>
                      <Button variant="contained" color="primary" onClick={handleEditClick}>Edit Profile</Button>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}

            {selectedTab === 1 && (
              <>
                {/* Account Settings Section */}
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Account Settings</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2}>
                    <Grid2 item xs={12} sm={6}>
                      <Chip label="Change Password" clickable />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Chip label="Notification Preferences" clickable />
                    </Grid2>
                  </Grid2>
                  <Box mt={2}>
                    <Typography variant="subtitle1">Enable Two-Factor Authentication</Typography>
                    <Switch checked={twoFactorEnabled} onChange={() => setTwoFactorEnabled(!twoFactorEnabled)} />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="subtitle1">Enable Notifications</Typography>
                    <Switch checked={notificationsEnabled} onChange={() => setNotificationsEnabled(!notificationsEnabled)} />
                  </Box>
                </Grid2>
              </>
            )}

            {selectedTab === 2 && (
              <>
                {/* Company Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Avatar src={userData.companyLogo} sx={{ width: 120, height: 120, mb: 2 }} />
                  <Typography variant="h6">{userData.companyName}</Typography>
                </Grid2>
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Company Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2}>
                    <Grid2 item xs={12} sm={6}>
                      <TextField fullWidth label="Company Name" value={userData.companyName} />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <TextField fullWidth label="Industry" placeholder="E.g., Marketing, Technology" />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <TextField fullWidth label="Company Address" placeholder="123 Main St, City, Country" />
                    </Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}

            {selectedTab === 3 && (
              <>
                {/* Billing Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Billing Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Button variant="outlined">Update Payment Method</Button>
                </Grid2>
              </>
            )}
          </Grid2>
        </Container>
      </Box>
    </Box>
  );
};

export default AdvertiserProfilePage;
