import React from 'react';
import { Card, CardContent, Typography, Grid2, Box, CircularProgress } from '@mui/material';
import { People, Campaign, Receipt, Layers } from '@mui/icons-material'; // Sample icons

const CampaignsOverview = ({ campaignData = [] }) => {
  // Handle empty campaignData gracefully
  if (!Array.isArray(campaignData)) {
    console.error("Invalid campaignData format. Expected an array.");
    return <Typography>Unable to display campaigns overview.</Typography>;
  }

  const statusCounts = campaignData.reduce((acc, job) => {
    const status = job?.status?.toLowerCase(); // Check if job and status exist
    if (status) {
      if (!acc[status]) {
        acc[status] = 0;
      }
      acc[status]++;
    }
    return acc;
  }, {});

  // Prepare the data for the cards (one card per status)
  const data = [
    {
      title: 'Live',
      value: statusCounts.live || 0,
      description: 'Active campaigns',
      icon: <Campaign />,
    },
    {
      title: 'Pending',
      value: statusCounts.pending || 0,
      description: 'Awaiting approval',
      icon: <Receipt />,
    },
    {
      title: 'Paused',
      value: statusCounts.paused || 0,
      description: 'Temporarily halted',
      icon: <Layers />,
    },
    {
      title: 'Draft',
      value: statusCounts.draft || 0,
      description: 'Work in progress',
      icon: <People />,
    },
  ];

  // Fallback if there's no data
  if (campaignData.length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
        <Typography sx={{ ml: 2 }}>No campaigns available to display.</Typography>
      </Box>
    );
  }

  return (
    <Grid2 container alignItems="center" justifyContent="space-between" spacing={3} sx={{ width: '100%' }}>
      {data.map((item, index) => (
        <Grid2 item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              width: 300, // Fixed width
              height: 140, // Fixed height
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 2,
              backgroundColor: index === 0 ? '#fff' : '#fff',
              color: index === 0 ? '#000' : '#000',
              borderRadius: 4,
            }}
          >
            {/* Left Side - Icon */}
            <Box>
              <Typography variant="h6" component="div">
                {item.value}
              </Typography>
              <Typography variant="subtitle1">{item.title}</Typography>
              <Typography variant="body2">{item.description}</Typography>
            </Box>

            {/* Right Side - Stats */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ bgcolor: '#f3f4f6', borderRadius: '50%', p: 2, mr: 2 }}>
                {item.icon}
              </Box>
            </Box>
          </Card>
        </Grid2>
      ))}
    </Grid2>
  );
};

export default CampaignsOverview;
