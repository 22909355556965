import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Container, Typography, Grid2, Skeleton, Tabs, Tab, IconButton } from '@mui/material';
import Sidebar from '../../../components/navigation/MainNav';
import CampaignsOverview from '../../../components/widgets/campaigns/CampaignsOverview';
import CreateCampaign from '../../../components/widgets/buttons/createCampaign';
import CampaignSummaryTable from '../../../components/widgets/dashboard/CampaignSummaryTable';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import ProfileAvatar from '../../../components/navigation/profileAvatar';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getUser, updateUser } from '../../../api/userApi';
import CreatorApplicationsSummaryTable from '../../../components/widgets/creators/ApplicationSummaryTable';
import CreatorJobSummaryTable from '../../../components/widgets/creators/JobSummaryTable';

const CreatorsJobsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState('All'); // Current selected status
  const [favourites, setFavourites] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [error, setError] = useState('');



  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    let isMounted = true;
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user_type === 'creator') {
        fetchJobs(isMounted);
     } else {
        navigate('/logout');
      }    
    }});
  
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [navigate]);



  const fetchJobs = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const accessToken = await user.getIdToken();
        const response = await axios.get(`https://ostendo.nucleotto.com/api/job/list`, {
        headers: { Authorization: `Bearer ${accessToken}` },
        });

      console.log(response.data.jobs);
      const jobsData = response.data.jobs;
      setJobs(jobsData || []);

      }

    } catch (error) {
      console.error('Error fetching applications:', error);
    } finally {
      setIsLoading(false);
    }
  };




  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Your Jobs</Typography>
             </Grid2>

       
            <Tabs
              value={selectedStatus}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="status filter tabs"
              sx={{ marginBottom: 2, marginTop: 4 }}
            >
              <Tab label="All" value="All" />
              <Tab label="Favourites" value="Favourites" />
              <Tab label="Accepted" value="Pending" />
              <Tab label="Declined" value="Offered" />
              <Tab label="Cancelled" value="Accepted" />
              <Tab label="Completed" value="Declined" />
            </Tabs>
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" width="100%" height={300} />
                </>
              ) : (
                <CreatorJobSummaryTable jobs={jobs} />
              )}
            </Grid2>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CreatorsJobsPage;
