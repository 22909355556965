import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ButtonGroup,
  CircularProgress,
  Box
} from '@mui/material';
import { statusColors } from '../../../config/status';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ApplicationsSummaryTable = ({ initialApplicationData = [], campaignDetails, creatorDetails, setCreatorDetails }) => {
  const [applicationData, setApplicationData] = useState(initialApplicationData);
  const [expandedRows, setExpandedRows] = useState({});
  const [counterFee, setCounterFee] = useState('');
  const [currentPlacementId, setCurrentPlacementId] = useState(null);
  const [openCounterFeeDialog, setOpenCounterFeeDialog] = useState(false);
  const [comment, setComment] = useState('');
  const [openCommentDialog, setOpenCommentDialog] = useState(false);
  const [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [currentApplicationId, setCurrentApplicationId] = useState(null);
  const navigate = useNavigate();
  const commentSource = 'user'; // Assuming this is a constant value
  const campaignId = campaignDetails._id;
  const [creatorMap, setCreatorMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCreatorDetails();
  }, []);

  const toggleRow = (applicationId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [applicationId]: !prev[applicationId],
    }));
  };

  const handleNewMessage = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user.uid;
    const isNewMessage = true;
    navigate('/messaging', { state: { applicationData, creatorDetails, userId, isNewMessage, campaignDetails } });
  };

  const handleApprove = (applicationId, placementId) => {
    updatePlacementStatus(applicationId, placementId, 'Approved');
  };

  const handleDecline = (applicationId, placementId) => {
    updatePlacementStatus(applicationId, placementId, 'Declined');
  };

  const handleOpenCommentDialog = (placementId) => {
    setCurrentPlacementId(placementId);
    setOpenCommentDialog(true);
  };

  const handleOpenCounterFeeDialog = (placementId) => {
    setCurrentPlacementId(placementId);
    setOpenCounterFeeDialog(true);
  };

  const handleCommentSubmit = () => {
    submitComment();
    setOpenCommentDialog(false);
    setComment('');
  };

  const submitComment = async () => {
    setIsLoading(true);
    try {
      const messageData = {
        campaignId: campaignDetails._id,
        to: applicationData.creatorId,
        text: comment,
        campaignName: campaignDetails.campaignName,
        newConversation: true,
      };

      await postMessage(messageData);
    } catch (error) {
      console.error('Error creating message', error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCounterFeeSubmit = async () => {
    await updatePlacementStatus(currentApplicationId, currentPlacementId, 'Pending', null, counterFee);
    await submitCounterFee();
    setOpenCounterFeeDialog(false);
    setCounterFee('');
  };

  const submitCounterFee = async () => {
    try {
      const messageData = {
        campaignId: campaignDetails._id,
        to: applicationData.creatorId,
        text: counterFee,
        campaignName: campaignDetails.campaignName,
        newConversation: true,
      };

      await postMessage(messageData);
    } catch (error) {
      console.error('Error creating message', error);
      throw error;
    }
  };

  const updatePlacementStatus = async (applicationId, placementId, status, comment = null, counterFee = null) => {
    setIsLoadingStatusUpdate(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const payload = {
        applicationId,
        updates: {
          placementId,
          status,
          comment,
          commentSource,
          counterFee,
        },
      };

      await axios.put(
        'https://ostendo.nucleotto.com/api/application/update',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      await getApplications(campaignId);

      console.log('Placement status updated successfully');
    } catch (error) {
      console.error('Error updating placement status:', error);
    } finally {
      setIsLoadingStatusUpdate(false);
    }
  };

  const handleLockAndOffer = (applicationId) => {
    lockAndOffer(applicationId);
    setIsLocked(true);
    setComment('');
  };

  const lockAndOffer = async (applicationId) => {
    setIsLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const payload = {
        applicationId,
        updates: {
          status: "Offered",
        },
      };

      await axios.put(
        'https://ostendo.nucleotto.com/api/application/offer',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      await getApplications(campaignId);

    
      setIsLocked(true);
      console.log('Placement status updated successfully');
    } catch (error) {
      console.error('Error updating placement status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleWithdrawOffer = (applicationId) => {
    withdrawOffer(applicationId);
    setIsLocked(false);
    setComment('');
  };

  const withdrawOffer = async (applicationId) => {
    setIsLoading(true);
    setIsLoadingStatusUpdate(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const payload = {
        applicationId,
        updates: {
          status: "Pending",
        },
      };

      await axios.put(
        'https://ostendo.nucleotto.com/api/application/offer',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      await getApplications();

      setIsLoadingStatusUpdate(false);

      console.log('Placement status updated successfully');
    } catch (error) {
      console.error('Error updating placement status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeclineOffer = (applicationId) => {
    declineOffer(applicationId);
    setIsLocked(true);
    setComment('');
  };

  const declineOffer = async (applicationId) => {
    setIsLoading(true);
    setIsLoadingStatusUpdate(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      const payload = {
        applicationId,
        updates: {
          status: "Declined",
        },
      };

      await axios.put(
        'https://ostendo.nucleotto.com/api/application/offer',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      await getApplications();

      setIsLoadingStatusUpdate(false);
      setIsLocked(true);
      console.log('Placement status updated successfully');
    } catch (error) {
      console.error('Error updating placement status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveApplication = (applicationId) => {
    lockAndOffer(applicationId);
  };

  const handleDeclineApplication = (applicationId) => {
    withdrawOffer(applicationId);
  };

  const handleApprovePlacement = (applicationId, placementId) => {
    handleApprove(applicationId, placementId);
  };

  const handleDeclinePlacement = (applicationId, placementId) => {
    handleDecline(applicationId, placementId);
  };

  const handleSubmitCounterOffer = () => {
    handleCounterFeeSubmit();
  };

  const handleOpenCounterOfferDialog = (applicationId, placementId) => {
    setCurrentApplicationId(applicationId);
    handleOpenCounterFeeDialog(placementId);
  };

  const getApplications = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/application/list',
          { campaignId: campaignId },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setApplicationData(response.data.applications || []);
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCreatorDetails = async () => {
    setIsLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.get(
          `https://ostendo.nucleotto.com/api/creator/getAll`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        const responseData = response.data.result;
        const creatorMap = responseData.reduce((map, creator) => {
          map[creator.userId] = creator;
          return map;
        }, {});
        setCreatorMap(creatorMap);
      }
    } catch (error) {
      console.error('Error fetching application by ID:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const postMessage = async (messageData) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const idToken = await user.getIdToken();

      await axios.post(
        'https://ostendo.nucleotto.com/api/message/send',
        messageData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2, marginTop: 6 }}>
      <Typography variant="h6" sx={{ padding: 2, fontWeight: 'bold' }}>
        Application Summary
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>Creator</strong></TableCell>
            <TableCell><strong>Campaign ID</strong></TableCell>
            <TableCell><strong>Actions</strong></TableCell>
          </TableRow>
        </TableHead>

         {isLoading ? (
        <Box fullWdith sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px'  }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableBody>
          {applicationData.map((application) => {
            const creator = creatorMap[application.creatorId];
            return (
              <React.Fragment key={application._id}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => toggleRow(application._id)}>
                      {expandedRows[application._id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                    {creator ? creator.email : application.creatorId}
                  </TableCell>
                  <TableCell>{application.campaignId}</TableCell>
                  <TableCell>
                    {application.status === 'Declined' ? (
                      <Chip label={application.status} color="error" /> 
                    ) : (
                    <ButtonGroup>
                      {application.status === 'Offered' ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleWithdrawOffer(application._id)}
                        >
                          WITHDRAW OFFER
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleLockAndOffer(application._id)}
                        >
                          SUBMIT OFFER
                        </Button>
                      )}
                      {application.status === 'Offered' ? (
                        <div style={{ width: '100px' }}></div>
                      ) : (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleDeclineOffer(application._id)}
                        >
                          DECLINE OFFER
                        </Button>
                      )}
                    </ButtonGroup> )}
                  </TableCell>
                </TableRow>

                {expandedRows[application._id] &&
                  application.placementsAppliedTo.map((placement) => (
                    <TableRow key={placement.placementId}>
                      <TableCell sx={{ paddingLeft: 8 }}>— {placement.placementId}</TableCell>
                      <TableCell colSpan={2}>
                        <Typography variant="body2">
                          Proposed Fee: ${placement.proposedFee || 0}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={placement.status}
                          sx={{
                            backgroundColor:
                              statusColors[placement.status]?.backgroundColor ||
                              statusColors.default.backgroundColor,
                            color:
                              statusColors[placement.status]?.color || statusColors.default.color,
                          }}
                        />
                        <ButtonGroup>
                          <IconButton
                            color="success"
                            onClick={() => handleApprovePlacement(application._id, placement.placementId)}
                            disabled={isLocked}
                          >
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeclinePlacement(application._id, placement.placementId)}
                            disabled={isLocked}
                          >
                            <CancelIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenCounterOfferDialog(application._id, placement.placementId)}
                            disabled={isLocked}
                          >
                            <CurrencyExchangeTwoToneIcon />
                          </IconButton>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
              </React.Fragment>
            );
          })}
        </TableBody>)}
      </Table>

      <Dialog open={openCounterFeeDialog} onClose={() => setOpenCounterFeeDialog(false)}>
        <DialogTitle>Make Counter Offer</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Counter Offer Amount"
            type="number"
            fullWidth
            variant="outlined"
            value={counterFee}
            onChange={(e) => setCounterFee(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCounterFeeDialog(false)}>Cancel</Button>
          <Button
            onClick={handleSubmitCounterOffer}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default ApplicationsSummaryTable;
