import React, { useState, useEffect } from 'react';
import { 
  Box, 
  CssBaseline, 
  Container, 
  Typography, 
  Grid2, 
  Avatar, 
  Divider, 
  TextField, 
  Button, 
  Tab, 
  Tabs,
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import { useLocation } from 'react-router-dom';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const BrandEditPage = () => {
  const location = useLocation();
  const { brand } = location.state || {}; // Ensure brand is correctly destructured
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0); // For managing 
  const [updateData, setUpdateData] = useState({});
  const navigate = useNavigate();

  const [file, setFile] = useState(null);
  const [blobUrl, setBlobUrl] = useState('');
  const [uploadingFileSuccessMessage, setUploadingFileSuccessMessage] = useState('');
  const [errorUploadingFile, setErrorUploadingFile] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }
  
    try {
      const response = await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', file, {
        headers: {
          'Content-Type': file.type || 'application/octet-stream',
          'X-Filename': file.name,
        },
      });
      if (response) {
        setUploadingFileSuccessMessage('File successfully uploaded');
      }
      if (!response.data.url) {
        setErrorUploadingFile('OOPS! we encountered an error, please press upload again.');
      } else {
        const imageURL = response.data.url;
        setBlobUrl(response.data.url);
        setUploadingFileSuccessMessage('File successfully uploaded');
        setUpdateData((prevData) => ({
          ...prevData,
          profile_image: imageURL,
        }));
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));  
  };

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
      navigate('/logout');
    }
    console.log("brand passed is:", brand);
    if (brand) {
      setIsLoading(false);
    }
  }, [brand, navigate]);

  const handleProfileUpdate = async () => {
    console.log('updateData:', updateData); // Debugging updateData
    try {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (user) {
        const accessToken = await user.getIdToken();
        const profileData = updateData;
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/profile/brand/update`,
          { profileId: brand._id, profileData },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
  
        if (response.data.result.acknowledged) {
          console.log('Brand updated successfully');
        }
  
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error updating brand:', error);
    } finally {
      navigate('/brands');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          {/* Profile Avatar and Header */}
          <Box>
            <ProfileAvatar />
          </Box>

          {/* Tabs for Profile Sections */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Profile Tabs"
            sx={{ marginBottom: 2, marginTop: 2 }}
          >
            <Tab label="Brand Information" />
            <Tab label="Billing Information" />
          </Tabs>

          {/* Profile Content */}
          <Grid2 container spacing={4} direction="column">
            {selectedTab === 0 && (
              <>
                {/* Personal Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Avatar src={brand.profile_image} sx={{ width: 120, height: 120, mb: 2 }} />
                  <Typography variant="h5" fontWeight="bold">{brand.company_name} </Typography>
                </Grid2>
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Change Profile Image</Typography>
                  <input type="file" onChange={handleFileChange} />
                  <Button variant="contained" onClick={handleUpload} disabled={!file}>
                    Upload
                  </Button>
                  <Typography variant="subtitle2">{errorUploadingFile}{uploadingFileSuccessMessage}</Typography>
                </Grid2>
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Brand Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2} width="600px" direction="column">
                    <Grid2 item>
                      <TextField
                        fullWidth
                        label="Brand Name"
                        name="company_name"
                        value={updateData.company_name || brand.company_name}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                    <Grid2 item>
                      <TextField
                        fullWidth
                        label="Website"
                        name="company_URL"
                        value={updateData.company_URL || brand.company_URL}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="About"
                        name="company_about"
                        value={updateData.company_about || brand.company_about}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}

            {selectedTab === 1 && (
              <>
                {/* Billing Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Billing Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Button variant="outlined">Update Payment Method</Button>
                </Grid2>
              </>
            )}

          

          </Grid2>

          <Grid2 item xs={12}>
              <Button variant="contained" color="primary" onClick={handleProfileUpdate} sx={{mt: 5}}>Submit Changes</Button>
            </Grid2>
        </Container>
      </Box>
    </Box>
  );
};

export default BrandEditPage;
