import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/navigation/MainNav';
import {
  Grid, Paper, List, ListItem, ListItemText, Typography, Box, TextField, Button, Divider, CssBaseline, Container, CircularProgress,
  IconButton, Avatar } from '@mui/material';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import SendIcon from '@mui/icons-material/Send';
import { useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { getUsersMessagesAll, postMessage } from '../../api/messaging';

const MessagingComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messageSendLoading, setMessageSendLoading] = useState(false);
  const [messageContent, setMessageContent] = useState('');
  const [conversations, setConversations] = useState([]); // For storing conversations
  const [conversationMessages, setConversationMessages] = useState([]); // For storing messages within a conversation
  const location = useLocation();
  const { applicationData, creatorDetails, userId, isNewMessage, campaignDetails } = location.state || {};
  const auth = getAuth();
  const user = auth.currentUser;
  const [newConversation, setNewConversation] = useState(false);
  const userType = localStorage.getItem('user_type'); // 'creator' or 'advertiser'

  // Poll for new messages every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchMessages(); // Fetch all messages
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchMessages(); // Fetch all messages
  }, []);

  const getLatestMessagesByCampaign = (messages) => {
    const latestMessagesMap = {};

    messages.forEach((message) => {
      const { campaignId, lastUpdated } = message;
      if (!latestMessagesMap[campaignId] || new Date(lastUpdated) > new Date(latestMessagesMap[campaignId].lastUpdated)) {
        latestMessagesMap[campaignId] = message;
      }
    });

    return Object.values(latestMessagesMap).sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
  };

  const fetchMessages = async (campaignId) => {
    try {
      const messages = await getUsersMessagesAll();
      console.log('messages:', messages);
      const latestMessages = getLatestMessagesByCampaign(messages);
      console.log(latestMessages);
      setConversations(latestMessages);
      setMessages(messages);

      if (campaignId) {
        const campaignMessages = messages.filter(msg => msg.campaignId === campaignId);
        setConversationMessages(campaignMessages);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle sending a message (new conversation or reply)
  const handleSendMessage = async () => {
    setMessageSendLoading(true);

    try {
      if (messageContent) {
        const otherUserId = conversationMessages.find(msg => msg.from !== user.uid)?.from || creatorDetails.userId;
        const messageData = {
          campaignId: selectedMessage.campaignId,
          campaignName: selectedMessage.campaignName,
          text: messageContent,
          fromRead: true,
          to: otherUserId,
        };

        const campaignId = selectedMessage.campaignId;
        await postMessage(messageData);
        await fetchMessages(campaignId);
        setMessageContent('');
        setMessageSendLoading(false);
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleSelectMessage = async (message) => {
    setSelectedMessage(message);
    console.log('campaignId:', message.campaignId);
    const campaignMessages = messages.filter(msg => msg.campaignId === message.campaignId);
    setConversationMessages(campaignMessages); // Load messages for the selected campaignId
    console.log('campaignMessages:', campaignMessages);

    if (user) {
      try {
        const idToken = await user.getIdToken();
        const unreadMessages = campaignMessages.filter(msg => msg.to === user.uid && !msg.read);

        for (const msg of unreadMessages) {
          await axios.put(
            `https://ostendo.nucleotto.com/api/campaign/messages/read?messageId=${msg._id}`,
            {},
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
              },
            }
          );
        }

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            unreadMessages.some(unreadMsg => unreadMsg._id === msg._id) ? { ...msg, read: true } : msg
          )
        );
      } catch (error) {
        console.error('Error updating message read status:', error);
      }
    }

    setNewConversation(false);
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f5f5f5', height: '100vh' }}>
      <CssBaseline />
      <Sidebar />

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Container maxWidth="xl">
            <Box sx={{ mb: 4 }}>
              <ProfileAvatar />
            </Box>

            <Grid container spacing={2} sx={{ height: '80vh' }}>
              {/* Left Panel: Conversation List */}
              <Grid item xs={12} md={3}>
                <Paper elevation={3} sx={{ height: '100%', overflowY: 'auto', p: 2, borderRadius: '20px' }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>Conversations</Typography>
                  <Divider sx={{ mb: 2 }} />
                  <List sx={{ maxHeight: 'calc(100% - 100px)', overflowY: 'auto' }}>
                    {conversations.map((conversation) => (
                      <ListItem
                        key={conversation.id}
                        button
                        onClick={() => handleSelectMessage(conversation)}
                        sx={{
                          backgroundColor: selectedMessage?.id === conversation.id ? '#eef4ff' : 'inherit',
                          mb: 1,
                          borderRadius: '8px',
                          '&:hover': { backgroundColor: '#f0f0f0' },
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                        }}
                      >
                        <Avatar alt={conversation.campaignName} src={conversation.avatarUrl} sx={{ mr: 2 }} />
                        <ListItemText
                          primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{conversation.subject}</Typography>}
                          secondary={<Typography variant="caption" color="textSecondary" sx={{ fontWeight: 'bold' }}>{conversation.campaignName} - {new Date(conversation.lastUpdated).toLocaleString()}</Typography>}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Grid>

              {/* Right Panel: Message Details */}
              <Grid item xs={12} md={9}>
                <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2, borderRadius: '20px' }}>
                  {newConversation ? (
                    <>
                      {/* New Message Header */}
                      <Box sx={{ borderBottom: '1px solid #e0e0e0', backgroundColor: '#f9f9f9', p: 2, position: 'sticky', top: 0, zIndex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{selectedMessage.subject}</Typography>
                      </Box>

                      {/* New Message Content */}
                      <Box sx={{ p: 2, flexGrow: 1, overflowY: 'auto', backgroundColor: '#fff' }}>
                        <Typography variant="body2" color="textSecondary">
                          Start a new conversation with {creatorDetails.email}.
                        </Typography>
                      </Box>

                      {/* Reply Section for New Message */}
                      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0', backgroundColor: '#f9f9f9', mt: 'auto', position: 'sticky', bottom: 0, zIndex: 1 }}>
                        <TextField
                          label="Message"
                          multiline
                          rows={2}
                          fullWidth
                          variant="outlined"
                          value={messageContent}
                          disabled={messageSendLoading}
                          onChange={(e) => setMessageContent(e.target.value)}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<SendIcon />}
                          sx={{ mt: 2 }}
                          onClick={handleSendMessage}
                          disabled={messageSendLoading}
                        >
                          Send
                        </Button>
                      </Box>
                    </>
                  ) : selectedMessage ? (
                    <>
                      {/* Message Header */}
                      <Box sx={{ borderBottom: '1px solid #e0e0e0', backgroundColor: '#f9f9f9', p: 2, position: 'sticky', top: 0, zIndex: 1 }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{selectedMessage.subject}</Typography>
                      </Box>

                      {/* Message Content */}
                      <Box
                        sx={{
                          p: 2,
                          flexGrow: 1,
                          overflowY: 'auto',
                          backgroundColor: '#fff',
                        }}
                      >
                        {conversationMessages.length > 0 ? (
                          conversationMessages.map((message, index) => (
                            <Box key={index} sx={{ mb: 3, display: 'flex', alignItems: 'flex-start' }}>
                              <Avatar alt={message.from} src={message.avatarUrl} sx={{ mr: 2 }} />
                              <Box sx={{ maxWidth: '80%', backgroundColor: message.from === user.uid ? '#e0f7fa' : '#f1f8e9', p: 2, borderRadius: '10px' }}>
                                <Typography variant="body2" color="textSecondary">
                                  {new Date(message.lastUpdated).toLocaleString()}
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 1 }}>
                                  {message.text}
                                </Typography>
                              </Box>
                            </Box>
                          ))
                        ) : (
                          <Typography>No messages in this conversation</Typography>
                        )}
                      </Box>

                      {/* Reply Section */}
                      <Box sx={{ p: 2, borderTop: '1px solid #e0e0e0', backgroundColor: '#f9f9f9', mt: 'auto', position: 'sticky', bottom: 0, zIndex: 1 }}>
                        <TextField
                          label="Reply"
                          multiline
                          rows={2}
                          fullWidth
                          variant="outlined"
                          value={messageContent}
                          onChange={(e) => setMessageContent(e.target.value)}
                          disabled={messageSendLoading}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<SendIcon />}
                          sx={{ mt: 2 }}
                          onClick={handleSendMessage}
                          disabled={messageSendLoading}
                        >
                          Send
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ p: 2 }}>
                      <Typography>Select a conversation to view its messages</Typography>
                    </Box>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default MessagingComponent;
