import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid2,
  TextField,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  FormControl,
  RadioGroup,
  Radio,
  FormLabel, MenuItem, Select, InputAdornment
  } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import { createCampaign } from '../../api/campaignsApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { getBrand } from '../../api/brandApi';
import { getAllBrands } from '../../api/brandApi'; // Import getAllBrands


// Upon creation can set budget per creator OR allow offers (tick box) -
// Campaign deliverables will need to be adjusted.
// Fix image uploads - to blob and return image.
// Finish field uploads
// Add in CPV objective



const CreateCampaign = () => {
const user = sessionStorage.getItem('userId');
const [blobUrl, setBlobUrl] = useState('');
const [isSubmitting, setIsSubmitting] = useState(false);
const user_type = localStorage.getItem('user_type');
const [imageUploadLoding, setImageUploadLoading] = useState(false);
const now = new Date();
const navigate = useNavigate();
const [campaignData, setCampaignData] = useState({
    brandId: '', 
    advertiserName: '',
    campaignName: '',
    jobType: {
      influencers: false,
      wordOfMouth: false,
      ugcAds: false,
      tvcAds: false,
    },
    remuneration: {
      influencerPayment: false,
      gifting: false,
      influencerPaymentAndGifting: false,
    },
    campaignSummary: '',
    campaignDescription: '',
    websiteLink: '',
    startDate: '',
    endDate: '',
    contentSubmittedBy: '',
    productInformation: '',
    productDelivery: '',
    contentExpectations: {
      influencerChannels: false,
      brandedCreativeAssets: false,
      repurposedByBrand: false,
      brandChannels: false,
      boostedOnBrandChannels: false,
      boostedOnInfluencerChannels: false, 
    },
    useagePeriodPerAsset: '',
    boostingPeriodPerAsset:'',
    usageDetails: '',
    contentPreApproval: '',
    influencerType: '',
    targetAudiences: '',
    targetAge: '',
    ugcContentPackages: '',
    ugcCreatorExperience: '',
    influencerSize: '',
    influencerGender: '',
    influencerCategoy: '',
    influencerLocation: '',
    influencerAge: '',
    audienceLocation: '',
    audienceGender: '',
    audienceAge: '',
    tvcCtvContentPackages: '',
    tvcCtvCrew: '',
    applicationCloseDate: '',
    createdBy: user,
    totalBudget: '',
    createdDate: now,
    contentSubmittedDate: '',
    image1: '',
    status: 'Draft',
    placements: []
  });
  const [brands, setBrands] = useState([]); // Add state for brands
  const [file, setFile] = useState(null); // State to store the selected file
  const [errorUploadingFile, setErrorUploadingFile] = useState('');
  const [canNotSubmitMessage, setCanNotSubmitMessage] = useState('');
  const [uploadingFileSuccessMessage, setUploadingFileSuccessMessage] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [dateError, setDateError] = useState('');
  const [applicationDateError, setApplicationDateError] = useState('');


  useEffect(() => {
    const fetchAdvertiserData = async () => {
    const user_type = localStorage.getItem('user_type');
    console.log("user type:", user_type);
    if (user_type === 'admin') {
    const brandsList = await getAllBrands();
    setBrands(brandsList);
    }
    if (user_type === 'brandAdmin' || user_type === 'brandUser') {
      const profileId = localStorage.getItem('brand_id');
      try {
      const advertiserRecord = await getBrand(profileId);
      const advertiserName = advertiserRecord[0].company_name;
      const brandId = advertiserRecord[0]._id;
      console.log('brandId:', brandId);
      console.log('advertiserRecord:', advertiserName);
      setCampaignData((prevData) => ({
        ...prevData,
        advertiserName: advertiserName,
        brandId:  brandId,
      }));
    } catch (error) {
      console.error('Error fetching advertiser data:', error);
    }
  }
    }
    fetchAdvertiserData();
}, []);

useEffect(() => {
  const {duplicateCampaignData} = location.state || {};
  if (duplicateCampaignData) {
    console.log("DUPLICATING CAMPAIGN DATA")
    setCampaignData((prevData) => ({
      ...prevData,
      ...duplicateCampaignData,
      placements: duplicateCampaignData.placements || [], // Ensure placements are initialized
    }));
  }
  }, []); 
  

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCampaignData({ ...campaignData, [name]: value });

    if (name === 'startDate' || name === 'endDate') {
      validateDates(name === 'startDate' ? value : campaignData.startDate, name === 'endDate' ? value : campaignData.endDate);
    }

    if (name === 'applicationCloseDate') {
      validateApplicationCloseDates(name === 'applicationCloseDate' ? value : campaignData.applicationCloseDate, name === 'endDate' ? value : campaignData.endDate);
    }


  };

  const validateDates = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setDateError('End date cannot be earlier than start date.');
    } else {
      setDateError('');
    }
  };

  const validateApplicationCloseDates = (applicationCloseDate, startDate) => {
    if (new Date(applicationCloseDate) > new Date(startDate)) {
      setApplicationDateError('Application close date cannot be later than start date.');
    } else {
      setApplicationDateError('');
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    setImageUploadLoading(true);
  
    try {
console.log('uploading');
const response =  await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', file, {
  headers: {
    'Content-Type': file.type || 'application/octet-stream',
    'X-Filename': file.name,
  },
});
if (response) {
  setImageUploadLoading(false);
  setUploadingFileSuccessMessage('File successfully uploaded');
}
if (!response.data.url) {
  setErrorUploadingFile('OOPS! we encountered an error, please press upload again.');
} else {
const imageURL = response.data.url;
setBlobUrl(response.data.url);
setUploadingFileSuccessMessage('File successfully uploaded');
setCampaignData((prevData) => ({
  ...prevData,
  image1: imageURL,
  
}));

}} catch (error) {
console.error('Error uploading file:', error);
} 
};
  
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData({ ...campaignData, [name]: checked });
  };

  const handleCheckboxChangeJobType = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      jobType: {
        ...prevData.jobType,
        [name]: checked
      }
    }));
  };

  const handleCheckboxContentExpectations = (event) => {
    const { name, checked } = event.target;
    console.log(name);
    setCampaignData((prevData) => ({
      ...prevData,
      contentExpectations: {
        ...prevData.contentExpectations,
        [name]: checked
      }
    }));
  };

  const handleCheckboxChangeRemuneration = (event) => {
    const { name, checked } = event.target;
    setCampaignData((prevData) => ({
      ...prevData,
      remuneration: {
        ...prevData.remuneration,
        [name]: checked
      }
    }));
  };

  const handleCheckboxChangeCampaignDeliverables = (event) => {
    const { name, checked } = event.target;
    const [platform, deliverable] = name.split(".");

    setCampaignData((prevData) => ({
      ...prevData,
      campaignDeliverables: {
        ...prevData.campaignDeliverables,
        [platform]: {
          ...prevData.campaignDeliverables[platform], // Safely spread the existing platform object
          [deliverable]: checked // Update the specific deliverable
        }
      }
    }));
  };

  useEffect(() => { 
    console.log('campaignData Job Type:', campaignData.jobType);
  }, [campaignData.jobType]);

  const addPlacement = () => {
    setCampaignData((prevData) => ({
      ...prevData,
      placements: [
        ...prevData.placements,
        {
          campaignType: campaignData.jobType,
          platform: '',
          format: '',
          resharing: '',
          boosting: '',
          usagePeriod: '',
          exclusivityPeriod: '',
          remuneration: '',
          fee: 0,
          status: 'Draft',
          comments: {
            campaignTypeComments: '',
            platformComments: '',
            formatComments: '',
            resharingComments: '',
            boostingComments: '',
            usagePeriodComments: '',
            exclusivityPeriodComments: '',
            remunerationComments: '',
            feeComments: ''
          }
        }
      ]
    }));
  };

  const handlePlacementChange = (index, event) => {
    const { name, value } = event.target;
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements[index] = {
        ...updatedPlacements[index],
        [name]: value
      };
      return { ...prevData, placements: updatedPlacements };
    });
  };

  const removePlacement = (index) => {
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements.splice(index, 1);
      return { ...prevData, placements: updatedPlacements };
    });
  };
  


  const handlePlacementCommentChange = (index, event) => {
    const { name, value } = event.target;
    setCampaignData((prevData) => {
      const updatedPlacements = [...prevData.placements];
      updatedPlacements[index].comments = {
        ...updatedPlacements[index].comments,
        [name]: value
      };
      return { ...prevData, placements: updatedPlacements };
    });
  };

  const handleSubmit = async (e) => {
    if (!blobUrl){
      setCanNotSubmitMessage('Missing fields: you must upload at least one image');
      setIsSubmitting(false);
      return;
    }
    setIsSubmitting(true);
    e.preventDefault();
    const submitCampaign = await createCampaign(campaignData); 
    console.log(submitCampaign);
    if (submitCampaign) {
      navigate(`/campaigns/detail/${submitCampaign}`, { state: { jobId: submitCampaign } });
    }
  };

  const handleNextTab = () => {
    setActiveTab((prevTab) => Math.min(prevTab + 1, 4));
  };
  
  const handlePreviousTab = () => {
    setActiveTab((prevTab) => Math.max(prevTab - 1, 0));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
          <ProfileAvatar />

            {/* Title and Button */}
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}></Typography>
              <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Saving...' : 'Save Draft'}
                
              </Button>
              
            </Grid2>

        
 <Box sx={{ maxWidth: '100%', margin: 'auto', padding: 3 }}>
 <Typography variant='subtitle2'>{canNotSubmitMessage}</Typography>

      {/* Tabs Navigation */}
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Campaign" />
        <Tab label="Deliverables" />
        <Tab label="Product" />
        <Tab label="Assets" />
        <Tab label="Influencer Profile" />

      </Tabs>

      {activeTab === 0 && (
        <TabPanel>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 8 }}>Campaign Summary</Typography>
          <Box maxWidth='800px'>
          <Grid2 item xs={12} md={8}>
  {/* For brandAdmin or brandUser, show the advertiser name field */}
  {user_type === 'brandAdmin' || user_type === 'brandUser' ? (
    <TextField
      fullWidth
      label="Advertiser Name"
      name="advertiserName"
      value={campaignData.advertiserName || ''}
      onChange={handleInputChange}
      disabled={user_type === 'brandAdmin' || user_type === 'brandUser'}
      sx={{ marginBottom: 3 }}
    />
  ) : user_type === 'admin' ? (
    /* For admin, show the brand selection dropdown */
    <TextField
      select
      label="Advertiser Name"
      name="brandId"
      value={campaignData.brandId || ''}
      onChange={(e) => {
        const selectedBrandId = e.target.value;
        const selectedBrand = brands.find((brand) => brand._id === selectedBrandId);

        // Update both advertiserName and brandId in the state
        handleInputChange({ target: { name: 'advertiserName', value: selectedBrand?.company_name || '' } });
        handleInputChange({ target: { name: 'brandId', value: selectedBrandId } });
      }}
      SelectProps={{
        native: true,
      }}
      fullWidth
      variant="outlined"
      sx={{ marginTop: 3 }}
      InputLabelProps={{ shrink: true }}
    >
      <option value="">Select a brand</option>
      {brands.map((brand) => (
        <option key={brand._id} value={brand._id}>
          {brand.company_name}
        </option>
      ))}
    </TextField>
  ) : null}
</Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField 
              fullWidth 
              label="Campaign Name" name="campaignName" 
              value={campaignData.campaignName} onChange={handleInputChange} 
              sx={{marginBottom: 3}}/>
              </Grid2>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Campaign Type</Typography>
             <Grid2 container spacing={2}>
             <Grid2 item xs={6} sm={3}>
  <RadioGroup
    row
    name="jobType"
    value={Object.keys(campaignData.jobType).find((key) => campaignData.jobType[key]) || ''}
    onChange={(e) => {
      const selectedType = e.target.value;
      setCampaignData((prevData) => ({
        ...prevData,
        jobType: {
          influencers: false,
          wordOfMouth: false,
          ugcAds: false,
          tvcAds: false,
          [selectedType]: true, // Set the selected type to true
        },
      }));
    }}
  >
    <FormControlLabel value="influencers" control={<Radio />} label="Influencers" />
    <FormControlLabel value="wordOfMouth" control={<Radio />} label="Word Of Mouth" />
    <FormControlLabel value="ugcAds" control={<Radio />} label="UGC Ads" />
    <FormControlLabel value="tvcAds" control={<Radio />} label="TVC / CTV Ads" />
  </RadioGroup>
</Grid2>
          
        </Grid2>
        <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Campaign Summary" name="campaignSummary" multiline rows={3} inputProps={{ maxLength: 150 }}
          helperText={`${campaignData.campaignSummary.length}/150`} value={campaignData.campaignSummary} onChange={handleInputChange} sx={{marginBottom: 3, marginTop: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Campaign Description" name="campaignDescription" multiline rows={6} value={campaignData.campaignDescription} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>

              <Grid2 item xs={12} md={8}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Total Campaign Budget</Typography>
              
              
              <TextField
                type="number"
                label="Amount"
                name="totalBudget"
                value={campaignData.totalBudget}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 3 }}
                fullWidth
              />
                 
               
              </Grid2>

              <Grid2 item xs={12} md={8}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Campaign Timing</Typography>
              <TextField
                type="date"
                label="Start Date"
                name="startDate"
                value={campaignData.startDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 3 }}
                 />
              <TextField
        type="date"
        label="End Date"
        name="endDate"
        value={campaignData.endDate}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2, mr: 3 }}
        error={!!dateError}
        helperText={dateError}
/>
<TextField
                type="date"
                label="Content Submitted By"
                name="contentSubmittedBy"
                value={campaignData.contentSubmittedBy}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 3 }}
        />
<TextField
                type="date"
                label="Application Close Date"
                name="applicationCloseDate"
                value={campaignData.applicationCloseDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2, mr: 3 }}
                error={!!applicationDateError}
                helperText={applicationDateError}
        />
              </Grid2>
              <Grid2 item xs={12} md={8} marginTop={4}>
              <Box>
      <Typography variant="h6" marginBottom={4} sx={{fontWeight: 'bold'}}>Upload a campaign image</Typography>

      <input type="file" onChange={handleFileChange} />
      
      <Button variant="contained" onClick={handleUpload} disabled={!file}
               >
                 {imageUploadLoding ? 'Uploading...' : 'Upload'}
                
              </Button>

        <Typography variant='subtitle2'>{errorUploadingFile}{uploadingFileSuccessMessage}</Typography>

     
    </Box>
              </Grid2>

          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" onClick={handleNextTab}>Next</Button>
          </Box>
        </TabPanel>
      )}

{activeTab === 1 && (

<TabPanel>
  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Deliverables</Typography>
{/* List of Placements */}

{campaignData.placements.map((placement, index) => (
      <Box key={index} sx={{ border: '1px solid #ccc', padding: 2, marginBottom: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>Placement {index + 1}</Typography>

  <Box maxWidth="100%" sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
    {/* Column for Labels */}
    <Grid2 container direction="column" xs={2} spacing={3} marginRight={14}>
     
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 3}}>Platform</Typography>
      </Grid2>
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 5}}>Format</Typography>
      </Grid2>
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 5}}>Resharing</Typography>
      </Grid2>
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 5}}>Boosting</Typography>
      </Grid2>
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 5}}>Usage Period</Typography>
      </Grid2>
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 5}}>Exclusivity Period</Typography>
      </Grid2>
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 5}}>Remuneration</Typography>
      </Grid2>
      <Grid2 item height={70}>
        <Typography variant="subtitle1" sx={{mt: 5}}>Fee</Typography>
      </Grid2>
    </Grid2>

    {/* Column for Inputs */}
    <Grid2 container direction="column" xs={4} spacing={3}>
      
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="platform"
            value={placement.platform || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Platform</em>
            </MenuItem>
            <MenuItem value="Influencers">Facebook</MenuItem>
            <MenuItem value="Brand">Instagram</MenuItem>
            <MenuItem value="UGC Ads">TikTok</MenuItem>
            <MenuItem value="TVC Ads">Youtube</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="format"
            value={placement.format || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Format</em>
            </MenuItem>
            <MenuItem value="Reel">Reel</MenuItem>
            <MenuItem value="Image">Image</MenuItem>
            <MenuItem value="Video">Video</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="resharing"
            value={placement.resharing || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Resharing</em>
            </MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="boosting"
            value={placement.boosting || ''}
            onChange={(e) => handlePlacementChange(index, e)}
                        displayEmpty
          >
            <MenuItem value="">
              <em>Select Boosting Option</em>
            </MenuItem>
            <MenuItem value="Influencers">Influencers</MenuItem>
            <MenuItem value="Brand">Brand</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="usagePeriod"
            value={placement.usagePeriod || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Usage Period</em>
            </MenuItem>
            <MenuItem value="1 Month">1 Month</MenuItem>
            <MenuItem value="6 Months">6 Months</MenuItem>
            <MenuItem value="12 Months">12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="exclusivityPeriod"
            value={placement.exclusivityPeriod || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Exclusivity Period</em>
            </MenuItem>
            <MenuItem value="1 Month">1 Month</MenuItem>
            <MenuItem value="6 Months">6 Months</MenuItem>
            <MenuItem value="12 Months">12 Months</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
          <Select
            name="remuneration"
            value={placement.remuneration || ''}
            onChange={(e) => handlePlacementChange(index, e)}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select Remuneration Type</em>
            </MenuItem>
            <MenuItem value="Product">Product</MenuItem>
            <MenuItem value="Payment">Payment</MenuItem>
            <MenuItem value="Product & Payment">Product & Payment</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <TextField
          fullWidth
          label="Fee"
          name="fee"
          value={placement.fee || ''}
          onChange={(e) => handlePlacementChange(index, e)} 
          inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}       />
      </Grid2>
    </Grid2>

    <Grid2 container direction="column" xs={12} spacing={3}>
    <Grid2 item>
    <FormControl fullWidth>
      <TextField
            name="campaignTypeComments"
            label="Campaign Type: Comments"
            value={placement.comments.campaignTypeComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
          </FormControl>
      </Grid2>
      <Grid2 item>
    <FormControl fullWidth>
      <TextField
            name="platformComments"
            label="Platform: Comments"
            value={placement.comments.platformComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
          </FormControl>
      </Grid2>
      
      <Grid2 item>
        <FormControl fullWidth>
          <TextField
            name="formatComments"
            label="Format: Comments"
            value={placement.comments.formatComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="resharingComments"
            label="Resharing: Comments"
            value={placement.comments.resharingComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="boostingComments"
            label="Boosting: Comments"
            value={placement.comments.boostingComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="usagePeriodComments"
            label="Usage Period: Comments"
            value={placement.comments.usagePeriodComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="exclusivityPeriodComments"
            label="Exlusivity Period: Comments"
            value={placement.comments.exclusivityPeriodComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="remunerationComments"
            label="Remuneration: Comments"
            value={placement.comments.remunerationComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
      <Grid2 item>
        <FormControl fullWidth>
        <TextField
            name="feeComments"
            label="Fee: Comments"
            value={placement.comments.feeComments || '' }
            onChange={(e) => handlePlacementCommentChange(index, e)}
            sx={{ minWidth: 400, width: '100%' }} 
         >      
          </TextField>
        </FormControl>
      </Grid2>
   
    </Grid2>
  </Box>
      {/* Remove Placement Button */}
      <Button variant="outlined" color="error" onClick={() => removePlacement(index)} sx={{ mt: 2 }}>
          Remove Placement
        </Button>
      </Box>
    ))}

    {/* Add Placement Button */}
    <Button variant="contained" onClick={addPlacement}>
      Add Placement
    </Button>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button variant="contained" onClick={handlePreviousTab}>Previous</Button>
      <Button variant="contained" onClick={handleNextTab}>Next</Button>
    </Box>
</TabPanel>

      )}
  
{activeTab === 2 && (
        <TabPanel>
      
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Product</Typography>
          <Box maxWidth='800px'>
          <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Product Information" name="productInformation" multiline rows={4} value={campaignData.productInformation} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>

          
          <Grid2 item xs={6} sm={3}>
          <FormControl>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Product Delivery</Typography>
          <RadioGroup
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="collectProduct" control={<Radio />} label="Creator will need to collect from a specific location" />
        <FormControlLabel value="deliverProduct" control={<Radio />} label="Creator will be shipped the product" />

      </RadioGroup>
    </FormControl>
         </Grid2>


              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" onClick={handlePreviousTab}>Previous</Button>
                <Button variant="contained" onClick={handleNextTab}>Next</Button>
              </Box>
        </TabPanel>
      )}

{activeTab === 3 && (
        <TabPanel>
    
          <Box maxWidth='800px'>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 3 }}>Content Expectations</Typography>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="influencerChannels" checked={campaignData.contentExpectations.influencerChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be published on Influencer channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="brandedCreativeAssets" checked={campaignData.contentExpectations.brandedCreativeAssets} onChange={handleCheckboxContentExpectations} />}
              label="Content will only be used as branded creative assets"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="repurposedByBrand" checked={campaignData.contentExpectations.repurposedByBrand} onChange={handleCheckboxContentExpectations} />}
              label="Content will be repurposed by the Brands"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="brandChannels" checked={campaignData.contentExpectations.brandChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be published on Brand Channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="boostedOnBrandChannels" checked={campaignData.contentExpectations.boostedOnBrandChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be boosted as ads from the Brand Channels"
            />
          </Grid2>
          <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="boostedOnInfluencerChannels" checked={campaignData.contentExpectations.boostedOnInfluencerChannels} onChange={handleCheckboxContentExpectations} />}
              label="Content will be boosted as ads from the Influencers channels"
            />
          </Grid2>
          <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Usage Period Per Asset" name="useagePeriodPerAsset" value={campaignData.useagePeriodPerAsset} onChange={handleInputChange} sx={{marginBottom: 3, marginTop: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Boosting Period Per Asset" name="boostingPeriodPerAsset" value={campaignData.boostingPeriodPerAsset} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={12} md={8}>
              <TextField fullWidth label="Usage Details" name="usageDetails" value={campaignData.usageDetails} onChange={handleInputChange} sx={{marginBottom: 3}}/>
              </Grid2>
              <Grid2 item xs={6} sm={3}>
            <FormControlLabel
              control={<Checkbox name="contentPreApproval" checked={campaignData.contentPreApproval} onChange={handleCheckboxChange} />}
              label="Content pre-approval before going live"
            />
          </Grid2>
              


              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" onClick={handlePreviousTab}>Previous</Button>
                <Button variant="contained" onClick={handleNextTab}>Next</Button>
              </Box>
        </TabPanel>
      )}


{activeTab === 7 && (
        <TabPanel>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>Influencer Profile</Typography>

       
        <Grid2 container spacing={2}>
         
      
        </Grid2>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" onClick={handlePreviousTab}>Previous</Button>
        </Box>
        </TabPanel>
      )}




  </Box>                  
              
          </Box>
          <Box align="right" marginTop='20px'>
          <Button variant="contained" onClick={handleSubmit} sx={{ borderRadius: '10px' }}
               disabled={isSubmitting} // Optionally disable the button while loading
               >
                 {isSubmitting ? 'Saving...' : 'Save Draft'}
                
              </Button>
              <Typography variant='subtitle2'>{canNotSubmitMessage}</Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children } = props;
  return (
    <Box sx={{ paddingTop: 3 }}>
      {children}
    </Box>
  );
};

export default CreateCampaign;
