import React, { useState, useEffect } from 'react';
import { 
  Box, 
  CssBaseline, 
  Container, 
  Typography, 
  Grid2, 
  Avatar, 
  Divider, 
  TextField, 
  Button, 
  Switch, 
  Chip,
  Tab,
  Tabs,
  RadioGroup,
  FormControlLabel,
  Radio,
 } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import { useLocation } from 'react-router-dom';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';

const UserEditPage = () => {
  const location = useLocation();
  const { userData } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0); // For managing 
  const [updateData, setUpdateData] = useState({});
  const user_type = localStorage.getItem('user_type');
  const navigate = useNavigate();


useEffect(() => {

  }, [updateData]); // Adding updateData as a dependency


  const handleProfileUpdate = async () => {
    console.log('updateData:', updateData); // Debugging updateData
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/user/updateUser`,
          { user: userData._id, updateData },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (response.data.result.acknowledged) {
          console.log('User updated successfully');
        }

        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      navigate('/users');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleUserTypeChange = (event) => {
    const userType = event.target.value;
    setUpdateData({ ...updateData, user_type: userType });
  };



  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          {/* Profile Avatar and Header */}
          <Box>
            <ProfileAvatar />
          </Box>

          {/* Tabs for Profile Sections */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Profile Tabs"
            sx={{ marginBottom: 2, marginTop: 2 }}
          >
            <Tab label="Personal Information" />
            <Tab label="Account Settings" />
            <Tab label="User Permissions" />
          </Tabs>

          {/* Profile Content */}
          <Grid2 container spacing={4} direction="column">
            {selectedTab === 0 && (
              <>
                {/* Personal Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Avatar src={userData.profile_image} sx={{ width: 120, height: 120, mb: 2 }} />
                  <Typography variant="h5" fontWeight="bold">{userData.firstName} {userData.lastName} </Typography>
                  <Typography variant="subtitle1" color="textSecondary">{userData.jobTitle}</Typography>  <Typography variant="subtitle2" color="textSecondary">{userData.user_type}</Typography>
                </Grid2>
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Personal Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2} width="600px" direction="column">
                    <Grid2 item>
                      <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        value={updateData.firstName || userData.firstName}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        value={updateData.lastName || userData.lastName}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={updateData.email || userData.email}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        name="mobile"
                        value={updateData.mobile || userData.mobile}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Job Title"
                        name="jobTitle"
                        value={updateData.jobTitle || userData.jobTitle}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid2>
                    
                  </Grid2>
                </Grid2>
              </>
            )}

            {selectedTab === 1 && (
              <>
                {/* Account Settings Section */}
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Account Settings</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2}>
                    <Grid2 item xs={12} sm={6}>
                      <Chip label="Change Password" clickable />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Chip label="Notification Preferences" clickable />
                    </Grid2>
                  </Grid2>
                  <Box mt={2}>
                    <Typography variant="subtitle1">Enable Two-Factor Authentication</Typography>
                    <Switch checked={twoFactorEnabled} onChange={() => setTwoFactorEnabled(!twoFactorEnabled)} />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="subtitle1">Enable Notifications</Typography>
                    <Switch checked={notificationsEnabled} onChange={() => setNotificationsEnabled(!notificationsEnabled)} />
                  </Box>
                </Grid2>
              </>
            )}

            {selectedTab === 2 && (
              <>
                {/* User Permissions */}
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">User Permissions</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2}>
                  <RadioGroup
           name="userType"
           value={updateData.user_type}
           onChange={handleUserTypeChange}
         >
          
        {user_type === 'admin' &&  <FormControlLabel value="admin" control={<Radio />} label="Admin" />}
               <FormControlLabel
                 value="brandAdmin"
                 control={<Radio />}
                 label="Brand Admin"
               />
               <FormControlLabel
                 value="brandUser"
                 control={<Radio />}
                 label="Brand User"
               />
      
         </RadioGroup>
                  </Grid2>
                </Grid2>
              </>
            )}

         
          </Grid2>

          <Grid2 item xs={12}>
                      <Button variant="contained" color="primary" onClick={handleProfileUpdate} sx={{mt: 5}}>Save Changes</Button>
                    </Grid2>
        </Container>
      </Box>
    </Box>
  );
};

export default UserEditPage;
