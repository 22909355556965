import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Container, Typography, Grid2, Skeleton, Tabs, Tab, IconButton } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import CampaignsOverview from '../../components/widgets/campaigns/CampaignsOverview';
import CreateCampaign from '../../components/widgets/buttons/createCampaign';
import CampaignSummaryTable from '../../components/widgets/dashboard/CampaignSummaryTable';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getUser, updateUser } from '../../api/userApi';

const CampaignsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [campaignData, setCampaignData] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]); // Filtered campaigns based on status
  const [selectedStatus, setSelectedStatus] = useState('All'); // Current selected status
  const [favourites, setFavourites] = useState([]);
  const [error, setError] = useState('');



  useEffect(() => {
    let isMounted = true;
    const user_type = localStorage.getItem('user_type');
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user_type === 'admin') {
        getCampaigns(isMounted);
        fetchFavourites();

      } else if (user_type === 'brandAdmin' || user_type === 'brandUser') {
        const brandId = localStorage.getItem('brand_id');
        getCampaigns(isMounted, brandId);
        fetchFavourites();
      } else {
        navigate('/logout');
      }    
    }});
  
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [navigate]);



  const fetchFavourites = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const accessToken = await user.getIdToken();
        const response = await axios.get('https://ostendo.nucleotto.com/api/user/favourites', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setFavourites(response.data);
      }
    } catch (error) {
      console.error('Error fetching favourites:', error);
    }
  };

  const updateFavourites = (updatedFavourites) => {
    setFavourites(updatedFavourites);
  };

  const getCampaigns = async (isMounted, brandId) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      let queryParams = new URLSearchParams();
      if (brandId) {
        queryParams.append('brandId', brandId);
      }
      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?${queryParams.toString()}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        if (isMounted) {
          setCampaignData(response.data.jobs || []);
          setFilteredCampaigns(response.data.jobs || []);
          setIsLoading(false);
        }
      }}  catch (error) {
        console.error('Error fetching campaigns:', error);
        setError('Failed to fetch campaigns. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    const handleStatusChange = (event, newStatus) => {
      setSelectedStatus(newStatus);
    
      if (newStatus === 'All') {
        setFilteredCampaigns(campaignData); // Show all campaigns
      } else if (newStatus === 'Favourites') {
        setFilteredCampaigns(campaignData.filter(campaign => favourites.includes(campaign._id)));
      } else {
        setFilteredCampaigns(campaignData.filter(campaign => campaign.status === newStatus));
      }
    };


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Campaigns</Typography>
              <CreateCampaign />
            </Grid2>

            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
              {/* Skeleton loading indicator */}
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" width={300} height={140} /><Skeleton variant="rounded" width={300} height={140} />
                  <Skeleton variant="rounded" width={300} height={140} /><Skeleton variant="rounded" width={300} height={140} />
                </>
              ) : (
                <CampaignsOverview campaignData={campaignData} />
              )}
            </Grid2>
            <Tabs
              value={selectedStatus}
              onChange={handleStatusChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="status filter tabs"
              sx={{ marginBottom: 2, marginTop: 4 }}
            >
              <Tab label="All" value="All" />
              <Tab label="Favourites" value="Favourites" />
              <Tab label="Live" value="Live" />
              <Tab label="Draft" value="Draft" />
              <Tab label="Paused" value="Paused" />
              <Tab label="Completed" value="Completed" />
              <Tab label="Cancelled" value="Cancelled" />
            </Tabs>
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" width="100%" height={300} />
                </>
              ) : (
                <CampaignSummaryTable 
                campaignData={filteredCampaigns}
                favourites={favourites}
                updateFavourites={updateFavourites}
                />
              )}
            </Grid2>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CampaignsPage;
