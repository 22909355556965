// src/pages/AdminProfilePage.js

import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  TextField,
  Paper,
  Tabs,
  Tab,
  CircularProgress
} from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { createBrand } from '../../api/brandApi';

const CreateBrandPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [brandData, setBrandData] = useState({
    company_name: '',
    company_URL: '',
    company_about: '',
    profile_image: ''
  });
  const [file, setFile] = useState(null);
  const [blobUrl, setBlobUrl] = useState('');
  const [error, setError] = useState('');
  const [uploadingFileSuccessMessage, setUploadingFileSuccessMessage] = useState('');
  const [errorUploadingFile, setErrorUploadingFile] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBrandData({ ...brandData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      const response = await axios.post('https://crowdswell.nucleotto.com/api/blob/uploadFile', file, {
        headers: {
          'Content-Type': file.type || 'application/octet-stream',
          'X-Filename': file.name,
        },
      });
      if (response) {
        setUploadingFileSuccessMessage('File successfully uploaded');
      }
      if (!response.data.url) {
        setErrorUploadingFile('OOPS! we encountered an error, please press upload again.');
      } else {
        const imageURL = response.data.url;
        setBlobUrl(response.data.url);
        setUploadingFileSuccessMessage('File successfully uploaded');
        setBrandData((prevData) => ({
          ...prevData,
          profile_image: imageURL,
        }));
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        await createBrand(brandData, idToken);
        navigate('/brands');
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error creating brand:', error);
      setError('Failed to create brand. Please try again later.');
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Typography variant="h5" sx={{ my: 4, mt: 6, fontWeight: 'bold' }} align="left">
              Create New Brand
            </Typography>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Brand Details" />
                <Tab label="Upload Image" />
              </Tabs>
              {activeTab === 0 && (
                <TabPanel>
                  <form onSubmit={handleSubmit}>
                    <Box maxWidth='800px'>
                      <Grid item xs={12} md={8}>
                        <TextField
                          label="Company Name"
                          variant="outlined"
                          fullWidth
                          name="company_name"
                          value={brandData.company_name}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Company URL"
                          variant="outlined"
                          fullWidth
                          name="company_URL"
                          value={brandData.company_URL}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Company About"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          name="company_about"
                          value={brandData.company_about}
                          onChange={handleInputChange}
                          required
                          sx={{marginBottom: 3}}
                        />
                      </Grid>
                      {error && (
                        <Grid item xs={12}>
                          <Typography color="error">{error}</Typography>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={isLoading}
                        >
                          {isLoading ? <CircularProgress size={24} /> : 'Create Brand'}
                        </Button>
                      </Grid>
                    </Box>
                  </form>
                </TabPanel>
              )}
              {activeTab === 1 && (
                <TabPanel>
                 <Box maxWidth='800px'>
                    <Typography variant="h6" marginBottom={4} sx={{ fontWeight: 'bold' }}>
                      Upload a profile image
                    </Typography>
                    <input type="file" onChange={handleFileChange} />
                    <Button variant="contained" onClick={handleUpload} disabled={!file}>
                      Upload
                    </Button>
                    <Typography variant="subtitle2">{errorUploadingFile}{uploadingFileSuccessMessage}</Typography>
                  </Box>
                </TabPanel>
              )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children } = props;
  return (
    <Box sx={{ paddingTop: 3 }}>
      {children}
    </Box>
  );
};

export default CreateBrandPage;
