import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Container, Typography, Tabs, Tab, Skeleton, Grid2 } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import CreatorsCampaignsOverview from '../../components/widgets/campaigns/CreatorsCampaignsOverview';
import CardGrid from '../../components/widgets/campaigns/campaignsCards';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import ProfileAvatar from '../../components/navigation/profileAvatar';

const CreatorsCampaignsPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [campaignData, setCampaignData] = useState([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState([]); // Filtered campaigns based on status
  const [selectedStatus, setSelectedStatus] = useState('All'); // Current selected status
  const [error, setError] = useState(null);

  useEffect(() => {
    const user_type = localStorage.getItem('user_type');
    if (!user_type) {
    navigate('/logout');
      console.log('User type not found');
    }
    getCampaigns();
  }, [navigate]);

  const getCampaigns = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/creator-list',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log('Campaigns:', response.data.jobs);
        setCampaignData(response.data.jobs || []); // Default to an empty array if no jobs
        setFilteredCampaigns(response.data.jobs || []); // Initialize filtered campaigns
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch campaigns');
      console.error('Error fetching campaigns:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = (event, newStatus) => {
    setSelectedStatus(newStatus);

    // Filter campaigns based on the selected status
    if (newStatus === 'All') {
      setFilteredCampaigns(campaignData); // Show all campaigns
    } else {
      const filtered = campaignData.filter(campaign => campaign.status === newStatus);
      setFilteredCampaigns(filtered);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          <Box>
            <ProfileAvatar />
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Overview</Typography>
            </Grid2>
           {/*} 
            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
      
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" width={300} height={140} />
                  <Skeleton variant="rounded" width={300} height={140} />
                  <Skeleton variant="rounded" width={300} height={140} />
                  <Skeleton variant="rounded" width={300} height={140} />
                </>
              ) : (
                <CreatorsCampaignsOverview campaignData={campaignData} />
              )} 
            </Grid2>*/}
            {/* Tabs for filtering by status */}
            <Tabs
              value={selectedStatus}
              onChange={handleStatusChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="status filter tabs"
              sx={{ marginBottom: 2, marginTop: 4 }}
            >
              <Tab label="All" value="All" />
              <Tab label="Live" value="Live" />
              <Tab label="Paused" value="Paused" />
              <Tab label="Completed" value="Completed" />
              <Tab label="Cancelled" value="Cancelled" />
            </Tabs>

          

            <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4, width: '100%' }}>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" width={300} height={450} sx={{ my: 2 }} />
                  <Skeleton variant="rounded" width={300} height={450} sx={{ my: 2 }} />
                  <Skeleton variant="rounded" width={300} height={450} sx={{ my: 2 }} />
                  <Skeleton variant="rounded" width={300} height={450} sx={{ my: 2 }} />
                </>
              ) : (
                <CardGrid campaignData={filteredCampaigns} />
              )}
            </Grid2>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CreatorsCampaignsPage;