import React, { useState, useEffect } from 'react';
import { 
  Box, 
  CssBaseline, 
  Container, 
  Typography, 
  Grid2, 
  Avatar, 
  Divider, 
  TextField, 
  Button, 
  Switch, 
  Chip,
  Tab,
  Tabs,
  Table,
  Paper,
  TableCell,
  TableBody,
  IconButton,
  TableHead,
  TableRow,
  TableContainer,
  TableSortLabel,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
 } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Sidebar from '../../components/navigation/MainNav';
import { useLocation } from 'react-router-dom';
import ProfileAvatar from '../../components/navigation/profileAvatar';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { getAllUsers } from '../../api/userApi';
import CreateUserAdmin from '../../components/widgets/buttons/createUserAdmin';

const UserDetailPage = () => {
  const location = useLocation();
  const { brand } = location.state || {}; // Ensure brand is correctly destructured
  const [isLoading, setIsLoading] = useState(true);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0); // For managing 
  const [usersData, setUsersData] = useState([]);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '_createdDate', direction: 'desc' });
  const [filterUserType, setFilterUserType] = useState('');
  const brandId = brand._id;


  const navigate = useNavigate();


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const { brand } = location.state || {}; // Ensure brand is correctly destructured
        const user_type = localStorage.getItem('user_type');
        
        // Redirect if no user type is found
        if (!user_type) {
          navigate('/logout');
          return;
        }

        console.log('Brand passed is:', brand);

        if (brand) {
          const brandId = brand._id;
          console.log('BRANDID IS:', brandId);

          await fetchUsers(brandId); // Fetch users for the given brand
        } else {
          console.error('No brand passed to the component');
          setError('No brand information available.');
        }
      } catch (error) {
        console.error('Error in fetchAllData:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData(); // Invoke the async function
  }, [location.state, navigate]);

  const fetchUsers = async (brandId) => {
    try {
      const usersData = await getAllUsers(brandId);

      if (Array.isArray(usersData)) {
        setUsersData(usersData);
        console.log('Set users state:', usersData); // Debug log
      } else {
        console.error('Fetched data is not an array:', usersData);
        setError('Invalid data format received.');
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users. Please try again later.');
    }
  };


  const handleEditClick = () => {
    navigate(`/brands/edit`, { state: { brand } });
  };

  const handleEditUserClick = (userData) => {
    console.log('Editing user:', userData); // Debugging user object
    navigate(`/users/edit`, { state: { userData } });
  };

  const handleViewUserClick = (userData) => {
    console.log('Viewing user:', userData); // Debugging user object
    navigate(`/users/detail`, { state: { userData } });
  };

  const handleClickCreate = (brandId) => {
    console.log('passing BrandId:', brandId); // Debugging user object
    navigate(`/users/create`, { state: { brandId } });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterUserType(event.target.value);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = [...usersData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const filteredUsers = sortedUsers.filter((user) =>
    (user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.user_type?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (filterUserType === '' || user.user_type === filterUserType)
  );


  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xxl">
          {/* Profile Avatar and Header */}
          <Box>
            <ProfileAvatar />
          </Box>

          {/* Tabs for Profile Sections */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Profile Tabs"
            sx={{ marginBottom: 2, marginTop: 2 }}
          >
            <Tab label="Brand Information" />
            <Tab label="Users" />
            <Tab label="Billing Information" />
          </Tabs>

          {/* Profile Content */}
          <Grid2 container spacing={4} direction="column">
            {selectedTab === 0 && (
              <>
                {/* Personal Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Avatar src={brand.profile_image} sx={{ width: 120, height: 120, mb: 2 }} />
                  <Typography variant="h5" fontWeight="bold">{brand.company_name} </Typography>
                </Grid2>
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Brand Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2} width="600px" direction="column">
                    <Grid2 item>
                      <Typography variant="subtitle1">Website: {brand.company_URL}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Typography variant="subtitle1">About: {brand.company_about}</Typography>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}

            {selectedTab === 1 && (
              <>
                {/* Account Settings Section */}
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Users</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <Grid2 item xs={3} minWidth={'300px'}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Filter by User Type</InputLabel>
                  <Select
                    value={filterUserType}
                    onChange={handleFilterChange}
                    label="User Type"
                  >
                    <MenuItem value=""><em>All</em></MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                    {/* Add more user types as needed */}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 item xs={6} />
              <Grid2 item xs={3} container justifyContent="flex-end" alignItems="center" spacing={2}>
                <Grid2 item xs={8} minWidth={'300px'}>
                  <TextField
                    label="Search Users"
                    variant="outlined"
                    fullWidth
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </Grid2>
                <Grid2 item xs={4}>
                <Button variant="contained" onClick={() => handleClickCreate(brandId)} sx={{borderRadius: '10px', backgroundColor: '#11ba82', height: '56px', mt: '10px'}}>Create User</Button>
                </Grid2>
              </Grid2>
            </Grid2>
            <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'displayName'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('displayName')}
                      >
                        <strong>Name</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'email'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('email')}
                      >
                        <strong>Email</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === 'user_type'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('user_type')}
                      >
                        <strong>User Type</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === '_createdDate'}
                        direction={sortConfig.direction}
                        onClick={() => handleSort('_createdDate')}
                      >
                        <strong>Created Date</strong>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <strong>Actions</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((userData) => (
                    <TableRow key={userData.userId} sx={{ cursor: 'pointer' }}>
                      <TableCell>{userData.displayName}</TableCell>
                      <TableCell>{userData.email}</TableCell>
                      <TableCell>{userData.user_type}</TableCell>
                      <TableCell>{new Date(userData._createdDate).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewUserClick(userData)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton onClick={() => handleEditUserClick(userData)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
                </Grid2>
              </>
            )}

            {selectedTab === 2 && (
              <>
                {/* Company Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Avatar src={brand.companyLogo} sx={{ width: 120, height: 120, mb: 2 }} />
                  <Typography variant="h6">{brand.companyName}</Typography>
                </Grid2>
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Company Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Grid2 container spacing={2}>
                    <Grid2 item xs={12} sm={6}>
                      <Typography variant="subtitle1">Company Name:</Typography>
                      <Typography variant="body1">{brand.companyName}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Typography variant="subtitle1">Industry:</Typography>
                      <Typography variant="body1">{brand.industry}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                      <Typography variant="subtitle1">Company Address:</Typography>
                      <Typography variant="body1">{brand.companyAddress}</Typography>
                    </Grid2>
                  </Grid2>
                </Grid2>
              </>
            )}

            {selectedTab === 3 && (
              <>
                {/* Billing Information Section */}
                <Grid2 item xs={12} mb={4}>
                  <Typography variant="h6">Billing Information</Typography>
                  <Divider sx={{ my: 2 }} />
                  <Button variant="outlined">Update Payment Method</Button>
                </Grid2>
              </>
            )}

        <Grid2 item xs={12}>
                      <Button variant="contained" color="primary" onClick={handleEditClick}>Edit Brand</Button>
                    </Grid2>

          </Grid2>
        </Container>
      </Box>
    </Box>
  );
};

export default UserDetailPage;
