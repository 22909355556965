import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Checkbox, FormControlLabel, Avatar, Grid2, Divider, Container, Paper,IconButton, } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { getAuth, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { registerUser } from '../../api/userApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { updateUser } from '../../api/userApi';

const PrimaryContact = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const location = useLocation();
  const [ loadingSubmit, setLoadingSubmit] = useState(false);
  const [ submittingError, setSubmittingError] = useState('');
  const userJSON = localStorage.getItem('user');
  const user = JSON.parse(userJSON);
  const recordId = localStorage.getItem('recordId')
  const [updateData, setUpdateData] = useState({
    mobile: user?.mobile || '',
    address: user?.address  || '',
  });


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdateData({ ...updateData, [name]: value });
  };




const handleConfirm = async () => {
  try {
    setLoadingSubmit(true);
    await updateUser(recordId, updateData);
    navigate('/register/primary-bio');
  } catch (error) {
    console.error("Error during Facebook login:", error);
    setSubmittingError('There has been a problem saving your details. Please contact your CrowdSwell representative')
  } finally {
    setLoadingSubmit(false);
  }
};


 return (
  <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Grid2 container spacing={4}>
      <Box maxWidth='400px' sx={{p: 5}}>
      {/* Left Section */}
      <Grid2 item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Step 4
        </Typography>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          Just a few more details
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
          We need to verify some details in order to set up your account
        </Typography>
      </Grid2>
      </Box>
      <Box maxWidth='400px' sx={{p: 3, background: '#e6e6e6'}}>
      {/* Right Section */}
      <Grid2 item xs={12} md={8} >
        {/* Option Cards */}
        <Grid2 container spacing={3} p={1} fullWidth>
        <Grid2 item xs={12} sm={6} md={6} >
          <Paper elevation={2}  sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px' }}>
              <Box><Typography variant="h6" sx={{ fontWeight: 'bold', mr: 6 }}>{user?.displayName}</Typography></Box>
         {/*}     <IconButton><CheckCircleIcon sx={{ fontSize: 40, color: 'green' }} /></IconButton> */}
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px'}}>
          <TextField
            fullWidth
            label="Mobile"
            type="mobile"
            name='mobile'
            variant="outlined"
            margin="normal"
            value={updateData.mobile}
            onChange={handleInputChange}
          />
          <IconButton></IconButton>
          </Paper>
          </Grid2>
          </Grid2>

          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px'}}>
          <TextField
            fullWidth
            label="Address"
            name='address'
            type="address"
            variant="outlined"
            margin="normal"
            value={updateData.address}
            onChange={handleInputChange}
          />
          <IconButton></IconButton>
          </Paper>
          </Grid2>
          </Grid2>


          <Grid2 container spacing={3} p={1}>
          <Grid2 item xs={12} sm={6} md={6}>
          <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  p: 2, height: '100%', maxWidth: '333px', width: '333px'}}>
          <Button align="right" variant='contained' onClick={handleConfirm} disabled={loadingSubmit}>{loadingSubmit ? 'Saving...' : 'CONFIRM'}</Button>
          <Typography variant='subtitle2'>{submittingError}</Typography>
          </Paper>
          </Grid2>
          </Grid2>


          
      </Grid2></Box>
    </Grid2>
  </Container>
);
};

export default PrimaryContact;
