import React from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid2, Avatar, Chip, Stack, CircularProgress } from '@mui/material';
import Sidebar from '../../components/navigation/MainNav';
import SearchBar from '../../components/widgets/dashboard/SearchBar';
import CampaignsOverview from '../../components/widgets/campaigns/CampaignsOverview';
import SettingsIcon from '@mui/icons-material/Settings';
import CardGrid from '../../components/widgets/campaigns/campaignsCards';
import CreateCampaign from '../../components/widgets/buttons/createCampaign';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getCampaignId } from '../../api/campaignsApi';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import { useState } from 'react';
import { updateCampaign } from '../../api/campaignsApi';
import { useNavigate } from 'react-router-dom';
import { statusColors } from '../../config/status';
import CreatorPlacementSummaryTable from '../../components/widgets/creators/PlacementSummaryTable';
import PendingCreatorPlacementSummaryTable from '../../components/widgets/creators/PendingPlacementSummaryTable';
import ProfileAvatar from '../../components/navigation/profileAvatar';

// When creator offers an amount - calculate a probability % of success or show an indiciator.
/// In future - can calculate per view worth per user based on social media followers etc.
// Retrieve Image from campaign record / document.
const jobTypeLabels = {
  influencers: "Influencers",
  wordOfMouth: "Word of Mouth",
  ugcAds: "UGC Ads",
  tvcAds: "TVC Ads",
};

const contentExpectationLabels = {
  influencerChannels: "Influencer Channels",
  brandedCreativeAssets: "Branded Creative Assets",
  repurposedByBrand: "Repurposed By Brand",
  brandChannels: "Brand Channels",
  boostedOnBrandChannels: "Boosted On Brand Channels",
  boostedOnInfluencerChannels: "Boosted On Influencer Channels"

};

const remunerationLabels = {
  influencerPayment: 'Influencer Payment',
  gifting: 'Influencer Gifting',
  influencerPaymentAndGifting: 'Influencer Payment & Gifting',
};



const CreatorCampaignDetail = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [placementData, setPlacementData] = useState([]);
  const user_type = localStorage.getItem('user_type');
  const [campaignDetails, setCampaignDetails] = useState([]);
  const [disableApplications, setDisableApplications] = useState(false);
  const [existingApplicationData, setExistingApplicationData] = useState([]);
  const [existingApplicationStatus, setExistingApplicationStatus] = useState('');
  const { jobId } = useParams();
  const [hasApplication, setHasApplication] = useState(false);
  const [placementLoading, setPlacementLoading] = useState(true);
  console.log(jobId);

  useEffect(() => {
    fetchCampaignDetails();
    getPlacements();
    }, []);

  const fetchCampaignDetails = async () => {
    
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          `https://ostendo.nucleotto.com/api/campaign/list?jobId=${jobId}`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        console.log(response.data.jobs[0]);
        setCampaignDetails(response.data.jobs[0] || []); // Default to an empty array if no jobs
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
     
    }
  };

  const getPlacements = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken();
        const response = await axios.post(
          'https://ostendo.nucleotto.com/api/campaign/placement/list',
          {campaignId: jobId},
          {
            headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${idToken}`,
            },
          }
        );
        setPlacementData(response.data.placements || []); // Default to an empty array if no jobs
      
        const applicationsResponse = await axios.post(
          'https://ostendo.nucleotto.com/api/application/creator-list',
          {campaignId: jobId},
          {
            headers: {
              'Content-Type': 'application/json',
               Authorization: `Bearer ${idToken}`,
            },
          }
        )
      if (applicationsResponse) {
        const placementsAppliedTo = applicationsResponse.data.applications.placementsAppliedTo || [];
        const placementsAppliedToStatus = applicationsResponse.data.applications.status;
        setHasApplication(true);
        setExistingApplicationData(placementsAppliedTo);
        setExistingApplicationStatus(placementsAppliedToStatus);
        setPlacementLoading(false);
        console.log(placementsAppliedToStatus)
      }
      }


    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setIsLoading(false);
    }
  };



  const handlePublish = async (e) => {
    setIsPublishLoading(true);
    e.preventDefault();
  
    // Determine the next status based on the current status
    const nextStatus = campaignDetails.status === 'Live' ? 'Paused' : 'Live';
  
    const updatedCampaignData = {
      status: nextStatus
    };
  
    const submitCampaign = await updateCampaign(jobId, updatedCampaignData);
  
    console.log(submitCampaign);
    if (submitCampaign) {
      window.location.reload(); // Refresh the page after successful submission
    }
  
    setIsPublishLoading(false); // Stop loading
  };

  const handleEditCampaign = async () => {
    
    navigate(`/campaigns/edit/${jobId}`, {state: {campaignDetails}})

  };





  //can not publish unless all fields have values that are not blank.

  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <Sidebar />
    {isLoading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 20 }}>
        <CircularProgress /> {/* Circular loader centered */}
      </Box>
    ) : (
      <>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth="xxl">
     <Box>
    
      <ProfileAvatar />
    

                {/* Title and Buttons */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4, mt: 4 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}></Typography>
                  <Grid2 item>
                  
                  </Grid2>
                </Grid2>

                {/* Campaign Name and Advertiser Name */}
                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mt: 4 }}>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                    {campaignDetails.campaignName}
                    <Chip
                      label={campaignDetails.status}
                      sx={{
                        ml: 3,
                        backgroundColor: statusColors[campaignDetails.status]?.backgroundColor || statusColors.default.backgroundColor,
                        color: statusColors[campaignDetails.status]?.color || statusColors.default.color,
                      }}
                    />
                  </Typography>
                </Grid2>

                <Grid2 container alignItems="center" justifyContent="space-between" sx={{ mb: 4 }}>
                  <Typography variant="h6">{campaignDetails.advertiserName}</Typography>
                </Grid2>
              </Box>

              {/* Main Content */}
       <Box maxWidth="100%" sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
       <Grid2 container direction="column" xs={2} spacing={3} marginRight={14} minWidth={200}>
      <Grid2 item height={22}>
        <Typography variant="subtitle1" fontWeight='bold'>Campaign Type</Typography>
      </Grid2>
      <Grid2 item height={22}>
        <Typography variant="subtitle1" fontWeight='bold'>Start Date</Typography>
      </Grid2>
      <Grid2 item height={22}>
        <Typography variant="subtitle1" fontWeight='bold'>End Date</Typography>
      </Grid2>
      <Grid2 item height={40}>
        <Typography variant="subtitle1" fontWeight='bold'>Campaign Summary</Typography>
      </Grid2>
      <Grid2 item height={56}>
        <Typography variant="subtitle1" fontWeight='bold'>Campaign Description</Typography>
      </Grid2>
      <Grid2 item height={40}>
        <Typography variant="subtitle1" fontWeight='bold'>Product Information</Typography>
      </Grid2>
      <Grid2 item height={22}>
        <Typography variant="subtitle1" fontWeight='bold'>Product Delivery</Typography>
      </Grid2>
    </Grid2>

    <Grid2 container direction="column" xs={4} spacing={3} marginRight={10} minWidth={500}>
    <Grid2 item height={22}>
    <Typography variant="subtitle1">{campaignDetails.campaignSummary}</Typography>
    </Grid2>
    <Grid2 item height={22}>
    <Typography variant="subtitle1">{campaignDetails.startDate}</Typography>
    </Grid2>
    <Grid2 item height={22}>
    <Typography variant="subtitle1">{campaignDetails.endDate}</Typography>
    </Grid2>
    <Grid2 item height={40}>
    <Typography variant="subtitle1">{campaignDetails.campaignSummary}</Typography>
    </Grid2>
    <Grid2 item height={56}>
    <Typography variant="subtitle1">{campaignDetails.campaignDescription}</Typography>
    </Grid2>
    <Grid2 item height={40}>
    <Typography variant="subtitle1">{campaignDetails.productInformation}</Typography>
    </Grid2>
    <Grid2 item height={22}>
    <Typography variant="subtitle1">{campaignDetails.productDelivery}</Typography>
    </Grid2>
    </Grid2>
    <Grid2 container direction="column" xs={12} spacing={3}>
    <Grid2 item>
      <img src={campaignDetails.image1} alt="hero" style={{ maxHeight: '600px', width: 'auto'}} />

      </Grid2>
    </Grid2>
               </Box>

               { hasApplication ? (
                
               <PendingCreatorPlacementSummaryTable 
               placementData={placementData} 
               campaignId={jobId} 
               existingApplicationData={existingApplicationData}
               existingApplicationStatus={existingApplicationStatus}
               />
               ) : (
                
                <CreatorPlacementSummaryTable 
                placementData={placementData} 
                campaignId={jobId} 
                campaignName={campaignDetails.campaignName}
                />
                         )}
          
            </Container>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreatorCampaignDetail;
